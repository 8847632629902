define("ember-cli-mirage/index", ["exports", "ember-cli-mirage/ember-data", "ember-cli-mirage/serializers/ember-data-serializer", "ember-cli-mirage/deprecate-imports", "ember-cli-mirage/deprecate-reexports", "miragejs"], function (_exports, _emberData, _emberDataSerializer, _deprecateImports, _deprecateReexports, _miragejs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "EmberDataSerializer", {
    enumerable: true,
    get: function () {
      return _emberDataSerializer.default;
    }
  });
  Object.defineProperty(_exports, "applyEmberDataSerializers", {
    enumerable: true,
    get: function () {
      return _emberData.applyEmberDataSerializers;
    }
  });
  _exports.default = void 0;
  Object.defineProperty(_exports, "discoverEmberDataModels", {
    enumerable: true,
    get: function () {
      return _emberData.discoverEmberDataModels;
    }
  });
  (0, _deprecateReexports.initDeprecatedReExports)();
  const DeprecatedFactory = function (...args) {
    (0, _deprecateImports.deprecateImport)('Factory');
    return _miragejs.Factory.call(this, ...args);
  };

  // Copy extend
  DeprecatedFactory.extend = _miragejs.Factory.extend;
  DeprecatedFactory.extractAfterCreateCallbacks = _miragejs.Factory.extractAfterCreateCallbacks;
  DeprecatedFactory.isTrait = _miragejs.Factory.isTrait;

  // // Store a reference on the class for future subclasses
  // DeprecatedFactory.attrs = newAttrs;

  class DeprecatedResponse extends _miragejs.Response {
    constructor(...args) {
      (0, _deprecateImports.deprecateImport)('Response');
      super(...args);
    }
  }
  function hasMany(...args) {
    (0, _deprecateImports.deprecateImport)('hasMany');
    return new _miragejs._ormAssociationsHasMany(...args);
  }
  function belongsTo(...args) {
    (0, _deprecateImports.deprecateImport)('belongsTo');
    return new _miragejs._ormAssociationsBelongsTo(...args);
  }
  var _default = _exports.default = {
    Factory: DeprecatedFactory,
    Response: DeprecatedResponse,
    hasMany,
    belongsTo
  };
});