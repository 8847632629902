define("ember-click-outside/modifier", ["exports", "@ember/runloop", "ember-modifier", "ember-click-outside/utils"], function (_exports, _runloop, _emberModifier, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class ClickOutsideModifier extends _emberModifier.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "action", null);
      _defineProperty(this, "capture", null);
      _defineProperty(this, "eventHandler", null);
      _defineProperty(this, "eventType", 'click');
      _defineProperty(this, "exceptSelector", null);
      _defineProperty(this, "cancelOutsideListenerSetup", null);
    }
    didInstall() {
      this._init();
      if ((0, _utils.ios)()) {
        document.body.style.cursor = 'pointer';
      }
    }
    didUpdateArguments() {
      this._destroy();
      this._init();
    }
    willRemove() {
      this._destroy();
      if ((0, _utils.ios)()) {
        document.body.style.cursor = '';
      }
    }
    _init() {
      let [action] = this.args.positional;
      let {
        capture,
        eventType,
        exceptSelector
      } = this.args.named;
      if (!action) {
        return;
      }
      this.action = action;
      this.exceptSelector = exceptSelector;
      this.capture = capture;
      if (eventType) {
        this.eventType = eventType;
      }
      this.eventHandler = this._createHandler(this.element, this.action, this.exceptSelector);
      this.cancelOutsideListenerSetup = (0, _runloop.next)(this, this._addClickOutsideListener);
    }
    _destroy() {
      if (!this.action) {
        return;
      }
      (0, _runloop.cancel)(this.cancelOutsideListenerSetup);
      this._removeClickOutsideListener();
    }
    _addClickOutsideListener() {
      let {
        capture,
        eventHandler,
        eventType
      } = this;
      document.addEventListener(eventType, eventHandler, {
        capture
      });
    }
    _removeClickOutsideListener() {
      let {
        capture,
        eventHandler,
        eventType
      } = this;
      document.removeEventListener(eventType, eventHandler, {
        capture
      });
    }
    _createHandler(element, action, exceptSelector) {
      return e => {
        if (exceptSelector && (0, _utils.closest)(e.target, exceptSelector)) {
          return;
        }
        let path = e.path || e.composedPath && e.composedPath();
        if (path) {
          path.includes(element) || action(e);
        } else {
          // Check if the click target still is in the DOM.
          // If not, there is no way to know if it was inside the element or not.
          let isRemoved = !e.target || !(0, _utils.documentOrBodyContains)(e.target);

          // Check the element is found as a parent of the click target.
          let isInside = element === e.target || element.contains(e.target);
          if (!isRemoved && !isInside) {
            action(e);
          }
        }
      };
    }
  }
  _exports.default = ClickOutsideModifier;
});